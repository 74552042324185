import { Link, useParams } from "react-router-dom"

import { useEffect, useState } from "react"

import classes from './AllLocations.module.css'

import { getItemFromStorage } from "../../scripts/localStorage"
import ArrowSliderRightIcon from "../../icons/arrowsliderright"
import { stateLabelValues } from "../../scripts/helpers"

const StatesLocations = () => {

    const [stateStores, setStateStores] = useState(null)
    const [currentState, setCurrentState] = useState(null)
    // const [bannerString, setBannerString] = useState(null)
    const params = useParams()
    
    const storage = getItemFromStorage('allLocations')   

    useEffect(() => {
        if (storage && storage.all) {
            if (params && params.stateName) {

                stateLabelValues?.forEach(item => {
                    if (item.value.toLowerCase() === params.stateName) {
                        setCurrentState(item.label)
                    }
                })

                let stateStore = storage.all.filter(item => item.address.state.toLowerCase() === params.stateName)

                if (stateStore) {
                    // setStateStores(stateStore)
                    // setBannerString(`${stateStore.length} stores in ${params.stateName}`)

                    let arr = []
                    stateStore.forEach(element => {
                        if (element.address && element.address.city) {
                            const index = arr.findIndex(object => {
                                return object.city === element.address.city
                            })
                            
                            if (index === -1) {
                                arr.push({
                                    city: element.address.city,
                                    slug: element.address.city.split(' ')?.join('_').toLowerCase(),
                                    // state: element.address.state,
                                    // stateFull: null,
                                    locations: []
                                })
                            }
                            
                        }
                    });
                    stateStore.forEach(element => {
                        arr.forEach(item => {
                            if (item.city === element.address.city) {
                                item.locations.push(element)
                            }
                        })
                    })
                    
                    arr.sort(function (a, b) {
                        return a.city.trim().localeCompare(b.city.trim());
                    })
                    
                    setStateStores(arr)
                } else {

                }
                
            }
        }
    }, [])


    return <div className={classes.container_wrapper}>
        <div className={classes.container}>
            <div className={classes.breadcrumb}>
                <Link to={'/directory'} className={classes.breadcrumb_link}>All Stores</Link>
                <span className={classes.separator}><ArrowSliderRightIcon /></span>
                <div className={classes.breadcrumb_current}>{currentState}</div>
            </div>
            <h2 className={classes.title}>{currentState} Locations</h2>

            <>
            {!stateStores && <>No stores found</>}
            {stateStores && <div className="grid-3 gap_24">
                {stateStores.map(item => (
                    <Link to={item.slug} key={item.city} className={classes.item}>
                        <div><span className={classes.state}>{item.city}</span> <span className={classes.store_number}>({item.locations.length})</span></div>
                        <ArrowSliderRightIcon />
                    </Link>
                ))}    
            </div>}
        </>
        </div>
    </div>
}

export default StatesLocations