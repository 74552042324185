import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Popup from './Popup'
import { formatOLODate, formatOLODateTime, formatOLOTime, getDayOfTheWeek, isVariableAnObject, splitAndReturnNormalDate, splitAndReturnNormalTime } from '../../scripts/helpers'
import { locationActions } from '../../store/location'

import classes from './PageWrapper.module.css'
import { cartActions } from '../../store/cart'
import { variousActions } from '../../store/various'
import { cartSetDeliveryMethod, createCart } from '../../scripts/fetchApi'
import { getItemFromStorage, setSessionStorageItem } from '../../scripts/localStorage'


const PageWrapper = ({children}) => {
    const dispatch = useDispatch()
    let location = useSelector(state => state.location.location)   
    let locationStorage = getItemFromStorage('currentLocation') 
    const cart = useSelector(state => state.cart.cart)
    const wasWarningShown = sessionStorage.getItem('wasWarningShown')
    const [storeClosed, setStoreClosed] = useState(false)
    const [loading, setLoading] = useState(false)
    const [btnWidth, setBtnWidth] = useState(null)


    const [noOnlineOrdering, setNoOnlineOrdering] = useState(false)
    const continueAndSetLocation = (e) => {
        setBtnWidth(e.target.getBoundingClientRect().width)
        setLocation()        
        sessionStorage.setItem('wasWarningShown', true)
    }

    const closeModalHandler = () => {
        setStoreClosed(false)
        dispatch(locationActions.setLocationsSideBarState())
        sessionStorage.setItem('wasWarningShown', true)
    }

    const setLocation = async () => {
        // new method to set location with cart.
        setLoading(true);


        // const newCart = await createCart({restaurant_id: data.id})
        const deliveryCartInfo = {
            delivery_mode: 'pickup',
            time: 'schedule',
            schedule_date: isVariableAnObject(location.hours) ? splitAndReturnNormalDate(location.hours.business[getDayOfTheWeek()]?.start) : null,
            schedule_time: isVariableAnObject(location.hours) ? splitAndReturnNormalTime(location.hours.business[getDayOfTheWeek()]?.start) : null
        }
        

        if (cart) { // if there is a cart, change method  
            deliveryCartInfo.schedule_date = formatOLODate(cart.earliestreadytime)
            deliveryCartInfo.schedule_time = formatOLOTime(cart.earliestreadytime)

            if (cart.timewanted) { // scheduled basket
                if (new Date() > new Date(formatOLODateTime(cart.timewanted))) { //time wanted is in the past!
                    if (location?.status?.open) {
                        deliveryCartInfo.time = 'asap'
                        deliveryCartInfo.schedule_date = null
                        deliveryCartInfo.schedule_time = null
                    }                     
                }                 
            }

            const cartMethod = await cartSetDeliveryMethod(cart.id, deliveryCartInfo)
            if (cartMethod.id) {
                //success
                dispatch(cartActions.addCart(cartMethod))
                // setStorageItem('currentCart', cartMethod)
                setSessionStorageItem('currentCart', cartMethod)
                setStoreClosed(false)
                // setLoading(false);
            } else {
                dispatch(variousActions.setError(cartMethod))
                setLoading(false);
            } 
                 
        } else { //if not, make a new cart
            const newCart = await createCart({restaurant_id: location.id})

            if (newCart.id) {

                deliveryCartInfo.schedule_date = formatOLODate(newCart.earliestreadytime)
                deliveryCartInfo.schedule_time = formatOLOTime(newCart.earliestreadytime)

                // console.log('no cart::: ', deliveryCartInfo)
                // console.log('cart::::::::::::::: ', cart)
                const cartMethod = await cartSetDeliveryMethod(newCart.id, deliveryCartInfo)
                if (cartMethod.id) {
                    //success
                    dispatch(cartActions.addCart(cartMethod))
                    // setStorageItem('currentCart', cartMethod)
                    setSessionStorageItem('currentCart', cartMethod)
                    setStoreClosed(false)
                    // setLoading(false);
                } else {
                    dispatch(variousActions.setError(cartMethod))
                    setLoading(false);
                }
            } else {
                dispatch(variousActions.setError(newCart))
                setLoading(false);
            }
            
        } 
    }


    useEffect(() => {

        if (location && location.status && location.status.message === "Currently unavailable to accept online orders.") {
            
        } else {
            if (!location && (locationStorage && locationStorage.currentLocation)) {
                dispatch(dispatch(locationActions.setLocationItem(locationStorage.currentLocation)))
            }
            if (location && location.status && !location.status.open && !wasWarningShown) { //store is not opened, show a modal
                setStoreClosed(true)
            } else {
                setStoreClosed(false)
            }
    
            if (location && cart) {
                if (location.id !== cart.vendorid) { //remove cart if ids don't match
                    dispatch(cartActions.addCart(null))
                }
            }
        }

    }, [location, cart, dispatch, locationStorage, wasWarningShown])



    

    // useEffect(() => {
    //     if (location && location.pathname) {
    //         if (location.pathname.includes('/menu')) {
    //             navigate(`/gac/${params.locationSlug}/${params.categoryId}`)
    //         }
    //     }
    // }, [location])


    const closeNoOrderingModalHandler = () => {
        setNoOnlineOrdering(false)
        dispatch(locationActions.setLocationsSideBarState())
    }


    useEffect(() => {
        if (location && location.status && location.status.message === "Currently unavailable to accept online orders.") {
            setNoOnlineOrdering(true)
        }
        
    }, [location])

    return  <>
        {children}
        {storeClosed && <Popup transition={storeClosed}>
            <p className={classes.warning_header}>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                <strong>Warning:</strong> {location.name} is not opened yet!
            </p>
            <p>The store will open today at {isVariableAnObject(location.hours) ? new Date(location.hours.business[getDayOfTheWeek()]?.start).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" }) : null}</p>
            <div className={classes.modal_controller}>
                <button className='btn btn-border' onClick={closeModalHandler}>Change Location</button>
                {!loading && <button className='btn' onClick={continueAndSetLocation}>Proceed Anyway</button>}
                {loading && <span className='btn' style={{width: btnWidth}}><span className='loader'></span></span>}
            </div>
        </Popup>}
        {noOnlineOrdering && <Popup transition={noOnlineOrdering}>
            <div className={classes.no_online_ordering_container}>
                <p className={classes.warning_header}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                    <strong>Warning:</strong> {location.name} is currently unavailable to accept online orders.
                </p>
                <div className={classes.modal_controller}>
                    <button className='btn' onClick={closeNoOrderingModalHandler}>Change Location</button>
                    {/* {!loading && <button className='btn' onClick={continueAndSetLocation}>Proceed Anyway</button>}
                    {loading && <span className='btn' style={{width: btnWidth}}><span className='loader'></span></span>} */}
                </div>
            </div>
        </Popup>}
    </>
}

export default PageWrapper