import { useEffect, useState } from 'react'
import classes from './Footer.module.css'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Footer = () => {
    const [mscLocation, setMscLocation] = useState(false)
    const [directoryPages, setIsDirectoryPages] = useState(false)
    // const storage = getItemFromStorage('currentLocation') 
    let location = useSelector(state => state.location.location) 
    useEffect(() => {
        // if (storage && storage.currentLocation && storage.currentLocation.data && storage.currentLocation.data.attributes && storage.currentLocation.data.attributes.includes("MSC")) {
        //     console.log('ceedeeeeeeeeeeeee')
        //     setMscLocation(true)
        // } else {
        //     setMscLocation(false)
        // }
        // if (location && location.data && location.data.attributes && location.data.attributes.includes("MSC")) {
        //     setMscLocation(true)
        // } else {
        //     setMscLocation(false)
        // }


        
        if (window.location.pathname.includes('directory')) {
            console.log('is directory page::::: ', window.location.pathname)
            setIsDirectoryPages(true)
        } else {
            setIsDirectoryPages(false)
        }
    }, [location])


    
    return <footer className={`${classes.footer} ${classes.msc_footer} ${directoryPages ? classes.directory_pages : ''}`}>
        <div className={classes.footer_top}>
            <div className='container-full'>
                <nav className={classes.footer_main_menu}>
                    <ul>
                        <li>
                            <Link to={'/directory'}>All Locations</Link>
                        </li>
                        <li>
                            <a href='https://www.marbleslab.com/our-story/' target='_blank' rel='noreferrer'>Our Story</a>
                        </li>
                        <li>
                            <a href='https://www.marbleslab.com/gift-cards/' target='_blank' rel='noreferrer'>Gift Cards</a>
                        </li>
                        <li>
                            <a href='https://www.marbleslab.com/wp-content/uploads/2023/02/ICE-CREAM-Nutrition-Allergens-2-8-23.pdf' target='_blank' rel='noreferrer'>Nutritionals</a>
                        </li>
                        <li>
                            <a href='https://www.fatbrands.com/brand/marble-slab-creamery/' target='_blank' rel='noreferrer'>Franchising</a>
                        </li>
                        <li>
                            <a href='https://portal.gfgmanagement.com/_layouts/GFG.SharePoint.AuthForm/login.aspx?ReturnUrl=%2f_layouts%2...' target='_blank' rel='noreferrer'>Franchise Login</a>
                        </li>
                    </ul>
                </nav>

                <div className={classes.copyright}>
                    © 2024 Marble Slab Creamery. We’re part of the FAT Brands Family.
                </div>

                <nav className={classes.footer_legal_menu}>
                    <ul>
                        <li>
                            <a href='https://www.marbleslab.com/contact/' target='_blank' rel='noreferrer'>Contact Us</a>
                        </li>
                        <li>
                            <a href="https://www.marbleslab.com/privacy-policy/" target='_blank' rel='noreferrer'>Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://www.marbleslab.com/accessibility-statement/" target='_blank' rel='noreferrer'>Accessibility Statement</a>
                        </li>
                    </ul>
                </nav>

                <nav className={classes.socials_menu}>
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/marbleslabcreameryofficial/" target='_blank' rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="60" viewBox="0 0 62 60" fill="none">                                    
                                    <path d="M16.135 57.2219C6.28204 53.0389 0.567018 46.2597 1.02402 36.7697C1.42358 29.5679 2.95154 22.4736 5.55203 15.7458C10.794 2.11078 18.317 -1.30056 36.052 0.405444C55.644 2.28344 62.4241 9.26683 60.7621 25.8058C60.2371 37.9708 56.072 48.3869 43.903 55.5739C39.346 58.4023 34.1031 59.9324 28.74 59.9997C24.3933 59.939 20.1045 58.994 16.135 57.2219Z" fill="white"/>
                                </svg>
                                <svg className={classes.social_icon} xmlns="http://www.w3.org/2000/svg" width="15" height="26" viewBox="0 0 15 26" fill="none">
                                    <path d="M15.001 4.31598H12.28C10.148 4.31598 9.73602 5.228 9.73602 6.565V9.51599H14.82L14.159 14.139H9.73702V26H4.43702V14.141H-0.000976562V9.51801H4.43502V6.10999C4.43502 2.15299 7.11803 0 11.035 0C12.912 0 14.526 0.125007 14.998 0.182007L15.001 4.31598Z" fill="var(--color-dark-raspberry)"/>
                                </svg>
                                <span className='sr-only'>facebook</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/marbleslab" target='_blank' rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="60" viewBox="0 0 62 60" fill="none">                                    
                                    <path d="M16.135 57.2219C6.28204 53.0389 0.567018 46.2597 1.02402 36.7697C1.42358 29.5679 2.95154 22.4736 5.55203 15.7458C10.794 2.11078 18.317 -1.30056 36.052 0.405444C55.644 2.28344 62.4241 9.26683 60.7621 25.8058C60.2371 37.9708 56.072 48.3869 43.903 55.5739C39.346 58.4023 34.1031 59.9324 28.74 59.9997C24.3933 59.939 20.1045 58.994 16.135 57.2219Z" fill="white"/>
                                </svg>
                                <svg className={classes.social_icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" fill="var(--color-dark-raspberry)"/></svg>
                                {/* <svg className={classes.social_icon} xmlns="http://www.w3.org/2000/svg" width="27" height="23" viewBox="0 0 27 23" fill="none">
                                    <path d="M26.9999 2.72591C25.9933 3.19435 24.9197 3.50273 23.8179 3.63991C24.9875 2.89556 25.8507 1.7556 26.2499 0.427906C25.1698 1.10189 23.9786 1.57895 22.7319 1.83691C22.2256 1.26322 21.6036 0.803272 20.9067 0.487335C20.2099 0.171398 19.454 0.00663988 18.6889 0.00390625C17.185 0.0403474 15.757 0.672122 14.7185 1.76051C13.6799 2.8489 13.1158 4.30493 13.1499 5.80891C13.149 6.25381 13.197 6.69746 13.2929 7.13191C11.0659 7.00134 8.89337 6.39161 6.92367 5.34437C4.95397 4.29713 3.23353 2.83706 1.87988 1.06391C1.38527 1.95712 1.12711 2.9619 1.12988 3.98291C1.1226 4.9271 1.34269 5.85917 1.77156 6.70037C2.20043 7.54157 2.82547 8.26719 3.59388 8.81591C2.71062 8.78558 1.84876 8.53609 1.08588 8.08991C1.08588 8.11391 1.08588 8.13891 1.08588 8.16291C1.06419 9.47701 1.49341 10.7589 2.30203 11.7949C3.11066 12.831 4.24987 13.5588 5.52988 13.8569C4.71647 14.0893 3.85925 14.1236 3.02988 13.9569C3.35667 15.0891 4.03323 16.089 4.96275 16.8134C5.89227 17.5378 7.02712 17.9496 8.20488 17.9899C7.07421 18.9284 5.76339 19.6254 4.35315 20.0382C2.94292 20.4509 1.46312 20.5706 0.00488281 20.3899C2.51146 22.0882 5.46918 22.9969 8.49688 22.9989C18.6809 23.0029 24.2499 14.1549 24.2499 6.48191C24.2499 6.23057 24.2445 5.98024 24.2339 5.73091C25.3267 4.90106 26.2632 3.88356 26.9999 2.72591Z" fill="var(--color-dark-raspberry)"/>
                                </svg> */}
                                <span className='sr-only'>twitter</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://instagram.com/marbleslabcreamery" target='_blank' rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="60" viewBox="0 0 62 60" fill="none">                                    
                                    <path d="M16.135 57.2219C6.28204 53.0389 0.567018 46.2597 1.02402 36.7697C1.42358 29.5679 2.95154 22.4736 5.55203 15.7458C10.794 2.11078 18.317 -1.30056 36.052 0.405444C55.644 2.28344 62.4241 9.26683 60.7621 25.8058C60.2371 37.9708 56.072 48.3869 43.903 55.5739C39.346 58.4023 34.1031 59.9324 28.74 59.9997C24.3933 59.939 20.1045 58.994 16.135 57.2219Z" fill="white"/>
                                </svg>
                                <svg className={classes.social_icon} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                    <path d="M7.5 0C3.4 0 0 3.4 0 7.5V18.4C0 22.6 3.4 26 7.5 26H18.4C22.6 26 25.9 22.6 25.9 18.5V7.5C26 3.4 22.6 0 18.5 0H7.5ZM7.5 2H18.4C21.5 2 24 4.5 24 7.5V18.4C24 21.5 21.5 23.9 18.5 23.9H7.5C4.5 24 2 21.5 2 18.5V7.5C2 4.5 4.5 2 7.5 2ZM20.5 4C19.7 4 19 4.7 19 5.5C19 6.3 19.7 7 20.5 7C21.3 7 22 6.3 22 5.5C22 4.7 21.3 4 20.5 4ZM13 6C9.1 6 6 9.1 6 13C6 16.9 9.1 20 13 20C16.9 20 20 16.9 20 13C20 9.1 16.9 6 13 6ZM13 8C15.8 8 18 10.2 18 13C18 15.8 15.8 18 13 18C10.2 18 8 15.8 8 13C8 10.2 10.2 8 13 8Z" fill="var(--color-dark-raspberry)"/>
                                </svg>
                                <span className='sr-only'>instagram</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/@marbleslabcreamery?_t=8fRIWwUac0I&_r=1" target='_blank' rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="60" viewBox="0 0 62 60" fill="none">                                    
                                    <path d="M16.135 57.2219C6.28204 53.0389 0.567018 46.2597 1.02402 36.7697C1.42358 29.5679 2.95154 22.4736 5.55203 15.7458C10.794 2.11078 18.317 -1.30056 36.052 0.405444C55.644 2.28344 62.4241 9.26683 60.7621 25.8058C60.2371 37.9708 56.072 48.3869 43.903 55.5739C39.346 58.4023 34.1031 59.9324 28.74 59.9997C24.3933 59.939 20.1045 58.994 16.135 57.2219Z" fill="white"/>
                                </svg>
                                <svg className={classes.social_icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" fill="var(--color-dark-raspberry)"/></svg>
                                {/* <svg className={classes.social_icon} xmlns="http://www.w3.org/2000/svg" width="22" height="27" viewBox="0 0 22 27" fill="none">
                                    <path d="M10.946 0.382812C3.86604 0.382812 0.102051 5.00976 0.102051 10.0518C0.102051 12.3908 1.38404 15.3089 3.43604 16.2339C4.02204 16.5029 3.94402 16.1739 4.44702 14.2109C4.47029 14.1316 4.47242 14.0476 4.45312 13.9673C4.43383 13.8869 4.39378 13.813 4.33704 13.7529C1.40304 10.2929 3.764 3.18018 10.526 3.18018C20.312 3.18018 18.484 16.9868 12.226 16.9868C11.8591 17.0118 11.4915 16.9503 11.153 16.8066C10.8144 16.663 10.5146 16.4414 10.2776 16.1602C10.0406 15.879 9.87312 15.546 9.78894 15.188C9.70476 14.83 9.70615 14.4574 9.79297 14.1001C10.254 12.2001 11.156 10.1552 11.156 8.78418C11.156 5.32818 6.10803 5.84197 6.10803 10.418C6.09782 11.2342 6.26533 12.0431 6.599 12.7881C6.599 12.7881 4.97497 19.4771 4.67297 20.7271C4.39689 22.6657 4.43741 24.6363 4.79297 26.562C4.79597 26.597 4.80976 26.6304 4.8324 26.6572C4.85503 26.6841 4.88546 26.703 4.91943 26.7119C4.95341 26.7208 4.98922 26.7189 5.02209 26.7065C5.05497 26.6942 5.08329 26.6721 5.10303 26.6431C6.22834 25.0647 7.12798 23.3367 7.776 21.5098C7.976 20.7578 8.797 17.71 8.797 17.71C9.22528 18.3016 9.79302 18.7782 10.4498 19.0977C11.1066 19.4171 11.8322 19.5694 12.562 19.541C17.508 19.541 21.084 15.107 21.084 9.60596C21.067 4.33296 16.64 0.385742 10.942 0.385742L10.946 0.382812Z" fill="var(--color-dark-raspberry)"/>
                                </svg> */}
                                <span className='sr-only'>tiktok</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div className={classes.footer_bottom}>
            <div className='container-full'>
                <div className={classes.footer_bottom_inner}>
                    Mars, Incorporated has no affiliation and has no participation in the production or distribution of this product. M&M'S® is a registered trademark of Mars, Incorporated {new Date().getFullYear()}.
                </div>
            </div>
        </div>
    </footer>
}

export default Footer