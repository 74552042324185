import { getItemFromStorage } from "./localStorage";

export const compareDate = (d1, d2) => {
    let date1 = new Date(d1).getTime();
    let date2 = new Date(d2).getTime();

    if (date1 < date2) {
        // console.log(`${d1} is less than ${d2}`);
        return d2
    } else if (date1 > date2) {
        // console.log(`${d1} is greater than ${d2}`);
        return d1
    } 
    
    
    console.log(`Both dates are equal`);
    return d1

}

export const  addMinutes = (date, minutes) => {
    let d = new Date(formatOLODateTime(date))
    d.setMinutes(d.getMinutes() + minutes);
    return d;
}


export const getDayOfTheWeek = () => {
    const date = new Date();
    const weekDay = date.toLocaleDateString('en-US', {
        weekday: 'short'
    })

    return weekDay;
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const formatAndSplitDates = (dateString, options) => {
    let trueDate = ''
    let time = ''
    const date = dateString.split(' ')
    date.forEach((element, index) => {
        if (index === 0) {
            const txt2 = element.slice(0, 4) + "-" + element.slice(4,6) + "-" + element.slice(6);
            trueDate = txt2
        }
        if (index === 1) {
            time = element
        }
        
    });

    const defaultOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        // hour: "2-digit",
        hour: 'numeric', 
        hour12: true,
        minute: "2-digit"
    };

    let setOptions = options ? options : defaultOptions

    const newDate = trueDate + 'T' + time


    return (new Date(newDate)).toLocaleString('en-US', setOptions)
}

export const formatOLODateTime = (dateToModify) => {
    let trueDate = ''
    let time = ''
    const date = dateToModify.split(' ')
    date.forEach((element, index) => {
        if (index === 0) {
            const txt2 = element.slice(0, 4) + "-" + element.slice(4,6) + "-" + element.slice(6);
            trueDate = txt2
        }
        if (index === 1) {
            time = element
        }
        
    });

    return trueDate + 'T' + time
}
export const formatOLODate = (dateToModify) => {
    let trueDate = ''
    // let time = ''
    let date = ''
    if (dateToModify) {
        date = dateToModify.split(' ')
        date.forEach((element, index) => {
            if (index === 0) {
                const txt2 = element.slice(0, 4) + "-" + element.slice(4,6) + "-" + element.slice(6);
                trueDate = txt2
            }
            
        });
    } 

    return trueDate
}

export const formatOLOTime = (dateToModify) => {

    let time = ''
    let date = ''
    if (dateToModify) {
        date = dateToModify.split(' ')
        date.forEach((element, index) => {
            if (index === 1) {
                // const txt2 = element.slice(0, 4) + "-" + element.slice(4,6) + "-" + element.slice(6);
                // trueDate = txt2
                time = element
            }
            
        });
    } 

    return time
}

export const formatOLOTimePLusMinutes = (dateToModify, minutes) => {    
    let time = ''
    let trueDate = ''
    let date = ''
    if (dateToModify) {
        date = dateToModify.split(' ')
        date.forEach((element, index) => {
            if (index === 0) {
                const txt2 = element.slice(0, 4) + "-" + element.slice(4,6) + "-" + element.slice(6);
                trueDate = txt2
            }
            if (index === 1) {
                // const txt2 = element.slice(0, 4) + "-" + element.slice(4,6) + "-" + element.slice(6);
                // trueDate = txt2
                time = element
            }
            
        });
    } 

    const t = new Date( trueDate+ 'T' + time)

    const modDate = new Date(t.getTime() + minutes * 60 * 1000)


    return modDate.toLocaleDateString('en-US', {
        year: 'numeric',
    }) + modDate.toLocaleDateString('en-US', {
        month: '2-digit',
    }) + modDate.toLocaleDateString('en-US', {
        day: '2-digit',
    }) + ' ' + modDate.toLocaleTimeString('en-US', {
        hour: "2-digit", 
        minute: "2-digit",
        hour12: false
    }) 
}

export const formatPhone = (value) => {
    let addChar = value.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    let limit = addChar.slice(0, 14);

    return limit
}


export const isEmpty = (value) => value.trim() === '';

export const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email);

export const isSame = (pass, confirmed_pass) => pass === confirmed_pass;

export const formatNumberDecimal = (number) => number.toFixed(2)

export const isLength5 = (value) => value.length >= 5;

export const modifiersImagepath = (value) => `https://gac-olo-backend.app.3owl.agency/storage/marbleslab/modifiers/${value.replace(/'/g, '').replace(/[^a-zA-Z ]+/g, '').replace(/ /g, '-').replace(/_+/g, '-').replace(/_$/, '').toLowerCase()}.png`
// https://stackoverflow.com/questions/32023117/regex-replace-2-or-more-dashes-with-one-dash 


export const generateTimeList = (startTime, endTime, currentDay) => {

    const timeList = []
    const now = new Date();
    const start = new Date(now);
    start.setHours(startTime.split(':')[0], startTime.split(':')[1], 0, 0);
    const end = new Date(now);
    end.setHours(endTime.split(':')[0], endTime.split(':')[1], 0, 0);
    const cHour = now.getHours();

    const cMinute = now.getMinutes();
    if (cMinute < 45) {
        
        if (cMinute < 30) {
            
            if (cMinute < 15) {
                start.setMinutes(0 + 15)
            } else {
                start.setMinutes(0 + 30)
            }
        } else {
            start.setMinutes(0 + 45)
        }
    } else {
        start.setMinutes(0)
        start.setHours(cHour + 1);
    }
    if (currentDay) {
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();
        start.setMinutes(currentMinute < 45 ? currentMinute + 15 : 0);
        start.setHours(currentHour + 1);
    }

    while (start <= end) {
        const formattedTimeUS = start.toLocaleTimeString('en-US', { hour12: true, minute: '2-digit', hour:'2-digit' });
        const formattedTimeIntl = start.toLocaleTimeString('en-US', { hour12: false, minute: '2-digit', hour:'2-digit' });
        
        // timeList[formattedTimeUS] = formattedTimeIntl;

        timeList.push({value: formattedTimeIntl, label: formattedTimeUS})
        
        start.setMinutes(start.getMinutes() + 15);
    }
    return timeList;
}


export const splitAndReturnNormalDate = (date) => {
    if (date) {
        let d = date.split(' ')[0]
        return d
    }
    

    return null
}

export const splitAndReturnNormalTime = (date) => {
    if (date) {
        let t = date.split(' ')[1]
        let timeArr = t.split(':')
        timeArr.pop()

        return timeArr.join(':')
    }
    

    return null
}

export const splitAndReturnNormalTimeOLO = (date) => {
    if (date) {
        let t = date.split(' ')[1]        

        return t
    }
    

    return null
}

export const getGenericToken = () => {
    const token = sessionStorage.getItem('token')

    if (token) {
        return token
    }

    const olotoken = sessionStorage.getItem('olotoken')

    if (olotoken) {
        return olotoken
    }

    return null
}


// const formatPhoneInput = (e) => {
//     try {
//         let phoneString = e.currentTarget.value
//         var cleaned = ("" + phoneString).replace(/\D/g, "");
//         var match = cleaned.match(/^(\d{0,3})?(\d{0,3})?(\d{0,4})?/);
//         console.log([match[1] ? "(": "",
//         match[1], 
//         match[2] ? ") ": "",
//         match[2],
//         match[3] ? "-": "",
//         match[3]].join(""))
//         return [match[1] ? "(": "",
//                 match[1], 
//                 match[2] ? ") ": "",
//                 match[2],
//                 match[3] ? "-": "",
//                 match[3]].join("")
        
//       } catch(err) {
//         return "";
//       }
// }

export class OffscreenCanvasPolly {
    constructor(width, height) {
      this.canvas = document.createElement("canvas");
      this.canvas.width = width;
      this.canvas.height = height;

      this.canvas.convertToBlob = () => {
        return new Promise(resolve => {
          this.canvas.toBlob(resolve);
        });
      };

      return this.canvas;
    }
};

export const addDataLayersSingle = (layerEvent, product) => {

    window.dataLayer = window.dataLayer || [];            
    window.dataLayer.push({
        event: layerEvent,
        ecommerce: {
            currency: "USD", // e.g. "USD",
            value: product.cost, // e.g. 7.77,
            items: [{
                item_id: product.id, 
                item_name: product.name, 
                // coupon: null, 
                // discount: null, // e.g. 2.22,
                // item_category: <item category>, // e.g. "Apparel",
                // item_list_id: <item list id>, // e.g. "related_products",
                // item_list_name: <item list name>, // e.g. "Related Products",
                price: product.cost, // e.g. 9.99,
                quantity: 1, // e.g. 1
            }]
        }
    });
}


export const addDataLayerCheckout = (order, location) => {

    let prodArray = []
    order.products.forEach(element => {
        prodArray.push({
            item_id: element.id,
            item_name: element.name,
            price: element.totalcost, // e.g. 9.99,
            quantity: element.quantity, // e.g. 1
        })
    });
    
    window.dataLayer = window.dataLayer || [];

    
    window.dataLayer.push({
    event: "purchase",
    ecommerce: {
        transaction_id: order.oloid, // e.g. "t_12345"
        tax: order.salestax, 
        currency: "USD", // e.g. "USD",
        value: order.total, // e.g. 7.77,
        coupon: order.coupon,
        payment_type: "credit card",
        store_name: location.name,
        items: prodArray
    }
    });
}



export const GACTreatwareRedirect = (data, slug) => {
    if (data.attributes && data.attributes.includes("GAC Treatware")) {
        window.location = `https://order.greatamericancookies.com/menu/${slug}`
    }
}


export function setCookie(name, value, days) {
    document.cookie = `${name}=${value}; path=/;  max-age=${60*60*24*days};`;
}


export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function deleteCookie(name) {
    document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; max-age=0`;
}



export function calcTime(locationStore, offset) {
    var d = new Date();
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    var nd = new Date(utc + (3600000*offset));
    if (locationStore && locationStore.hours && locationStore.hours.business && locationStore.hours.business[getDayOfTheWeek()]) {
        return (new Date(locationStore.hours.business[getDayOfTheWeek()]?.start)) < nd
    } else {
        return null
    }
    
}


export function isVariableAnObject(x) {
    return typeof x === 'object' && !Array.isArray(x) && x !== null
}


// export function isMSCChangeStyle(storage) {
//     if (storage && storage.currentLocation && storage.currentLocation.data && storage.currentLocation.data.attributes && storage.currentLocation.data.attributes.includes("MSC")) {
//         document.documentElement.style.cssText = `
//             --color-cocoa: #584646;
//             --color-raspberry: #08616a;
//             --color-mint: #5bc6cc;    
//             --color-text-blue: #242c37;

//             /* colors  secondary */
//             /* --color-dark-raspberry: #0b818e; */
//             --color-dark-raspberry: #0b818e;
//             /* --color-dark-raspberry-secondary: #8d1e59; */
//             --color-dark-raspberry-secondary: #0b818e;
//             --color-red: #AA0061;
//             --color-dark-mint: #0B818E;
//             --color-lemon: #f8de8d;
//             --color-cream: #FFEAD4;
//             --color-light-mint: #9bd7d7;
//             --color-strawberry: #ebc6de;
//             --color-sugar: #dcc5b7;
//             --color-pink: #ECC5DE;

//             /* colors  aditional */
//             --color-grey-bg: #f9f9f9;
//             --color-grey-stroke: #d6d8d9;
//             --color-grey-stroke-dark: #B2B5B9;
//             --color-light-bg: #f8e0be;
//             --color-light-bg-v2: #FBFAF8;
//             --color-border: #E3E5E7;
//         `;
//     } else {
//         document.documentElement.style.cssText = `
//             --color-cocoa: #584646;
//             --color-raspberry: #b7006a;
//             --color-mint: #5bc6cc;    
//             --color-text-blue: #242c37;

//             /* colors  secondary */
//             --color-dark-raspberry: #8d0e57;
//             --color-dark-raspberry-secondary: #8d1e59;
//             --color-red: #AA0061;
//             --color-dark-mint: #0B818E;
//             --color-lemon: #f8de8d;
//             --color-cream: #FFEAD4;
//             --color-light-mint: #9bd7d7;
//             --color-strawberry: #ebc6de;
//             --color-sugar: #dcc5b7;
//             --color-pink: #ECC5DE;

//             /* colors  aditional */
//             --color-grey-bg: #f9f9f9;
//             --color-grey-stroke: #d6d8d9;
//             --color-grey-stroke-dark: #B2B5B9;
//             --color-light-bg: #f8e0be;
//             --color-light-bg-v2: #FBFAF8;
//             --color-border: #E3E5E7;
//         `;
//     }
// }

// export function isMSCChangeStyleStore(location) {
//     if (location && location.data && location.data.attributes && location.data.attributes.includes("MSC")) {
//         document.documentElement.style.cssText = `
//             --color-cocoa: #584646;
//             --color-raspberry: #08616a;
//             --color-mint: #5bc6cc;    
//             --color-text-blue: #242c37;

//             /* colors  secondary */
//             /* --color-dark-raspberry: #0b818e; */
//             --color-dark-raspberry: #0b818e;
//             /* --color-dark-raspberry-secondary: #8d1e59; */
//             --color-dark-raspberry-secondary: #0b818e;
//             --color-red: #AA0061;
//             --color-dark-mint: #0B818E;
//             --color-lemon: #f8de8d;
//             --color-cream: #FFEAD4;
//             --color-light-mint: #9bd7d7;
//             --color-strawberry: #ebc6de;
//             --color-sugar: #dcc5b7;
//             --color-pink: #ECC5DE;

//             /* colors  aditional */
//             --color-grey-bg: #f9f9f9;
//             --color-grey-stroke: #d6d8d9;
//             --color-grey-stroke-dark: #B2B5B9;
//             --color-light-bg: #f8e0be;
//             --color-light-bg-v2: #FBFAF8;
//             --color-border: #E3E5E7;
//         `;
//     } else {
//         document.documentElement.style.cssText = `
//             --color-cocoa: #584646;
//             --color-raspberry: #b7006a;
//             --color-mint: #5bc6cc;    
//             --color-text-blue: #242c37;

//             /* colors  secondary */
//             --color-dark-raspberry: #8d0e57;
//             --color-dark-raspberry-secondary: #8d1e59;
//             --color-red: #AA0061;
//             --color-dark-mint: #0B818E;
//             --color-lemon: #f8de8d;
//             --color-cream: #FFEAD4;
//             --color-light-mint: #9bd7d7;
//             --color-strawberry: #ebc6de;
//             --color-sugar: #dcc5b7;
//             --color-pink: #ECC5DE;

//             /* colors  aditional */
//             --color-grey-bg: #f9f9f9;
//             --color-grey-stroke: #d6d8d9;
//             --color-grey-stroke-dark: #B2B5B9;
//             --color-light-bg: #f8e0be;
//             --color-light-bg-v2: #FBFAF8;
//             --color-border: #E3E5E7;
//         `;
//     }
// }


export function locationHomeUrl(location) {
    if (location) {
        // if (location.data && location.data.attributes && location.data.attributes.includes("MSC")) {            
        //     return(`/marble-slab-creamery/${location.location_slug}`)
        // } else if (location.data && location.data.attributes && (location.data.attributes.findIndex(element => element.includes("CNC")) >= 0)) {            
        //     return(`/co-marble-slab-creamery/${location.location_slug}`)
        // } else {
        //     return(`/gac/${location.location_slug}`)
        // }

        return(`/menu/${location.location_slug}`)
    }
}

export function isLocationCNC(data) {
    if (data && data.attributes && data.attributes.includes("CNC")) {
        return true
    } 

    if (data && data.data && data.data.attributes && data.data.attributes.includes("CNC")) {
        return true
    } 

    return false
}


export const stateLabelValues = [
    { label:'Alabama', value: 'AL' },
    { label:'Alaska', value: 'AK'},
    { label:'American Samoa', value: 'AS'},
    { label:'Arizona', value: 'AZ'},
    { label:'Arkansas', value: 'AR'},
    { label:'California', value: 'CA'},
    { label:'Colorado', value: 'CO'},
    { label:'Connecticut', value: 'CT'},
    { label:'Delaware', value: 'DE'},
    { label:'District of Columbia', value: 'DC'},
    { label:'States of Micronesia', value: 'FM'},
    { label:'Florida', value: 'FL'},
    { label:'Georgia', value: 'GA'},
    { label:'Guam', value: 'GU'},
    { label:'Hawaii', value: 'HI'},
    { label:'Idaho', value: 'ID'},
    { label:'Illinois', value: 'IL'},
    { label:'Indiana', value: 'IN'},
    { label:'Iowa', value: 'IA'},
    { label:'Kansas', value: 'KS'},
    { label:'Kentucky', value: 'KY'},
    { label:'Louisiana', value: 'LA'},
    { label:'Maine', value: 'ME'},
    { label:'Marshall Islands', value: 'MH'},
    { label:'Maryland', value: 'MD'},
    { label:'Massachusetts', value: 'MA'},
    { label:'Michigan', value: 'MI'},
    { label:'Minnesota', value: 'MN'},
    { label:'Mississippi', value: 'MS'},
    { label:'Missouri', value: 'MO'},
    { label:'Montana', value: 'MT'},
    { label:'Nebraska', value: 'NE'},
    { label:'Nevada', value: 'NV'},
    { label:'New Hampshire', value: 'NH'},
    { label:'New Jersey', value: 'NJ'},
    { label:'New Mexico', value: 'NM'},
    { label:'New York', value: 'NY'},
    { label:'North Carolina', value: 'NC'},
    { label:'North Dakota', value: 'ND'},
    { label:'Northern Mariana Islands', value: 'MP'},
    { label:'Ohio', value: 'OH'},
    { label:'Oklahoma', value: 'OK'},
    { label:'Oregan', value: 'OR'},
    { label:'Palau', value: 'PW'},
    { label:'Pennsilvania', value: 'PA'},
    { label:'Puerto Rico', value: 'PR'},
    { label:'Rhode Island', value: 'RI'},
    { label:'South Carolina', value: 'SC'},
    { label:'South Dakota', value: 'SD'},
    { label:'Tennessee', value: 'TN'},
    { label:'Texas', value: 'TX'},
    { label:'Utah', value: 'UT'},
    { label:'Vermont', value: 'VT'},
    { label:'Virgin Islands', value: 'VI'},
    { label:'Virginia', value: 'VA'},
    { label:'Washington', value: 'WA'},
    { label:'West Virginia', value: 'WV'},
    { label:'Wisconsin', value: 'WI'},
    { label:'Wyoming', value: 'WY'}
];