import { useEffect, useRef, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux';
import { locationActions } from '../../store/location';

import classes from './Header.module.css'

import logoCoBranded from '../../assets/FATB_GACMSC_Logo_D.svg'
import logoCoBrandedMobile from '../../assets/FATB_GACMSC_Logo_M.svg'
import logoGAC from '../../assets/logo-gac.svg'
import logoMSC from '../../assets/logo-marble.svg'

import { Link } from 'react-router-dom';
import { useMediaQuery } from '../../hooks/hooks';
import HeaderCartButton from './HeaderCartButton';
import HeaderLocationDesktop from './HeaderLocationDesktop';
import HeaderSignIn from './HeaderSignIn';
import HeaderLocationMobile from './HeaderLocationMobile';

import SidebarContainer from './SidebarContainer';
import SidebarAuthContainer from './SidebarAuthContainer';
import SidebarLocationContainer from './SidebarLocationContainer';
import HeaderWrapper from './HeaderWrapper';

import { locationHomeUrl, setCookie } from '../../scripts/helpers';
import Popup from '../common/Popup';
import { authActions } from '../../store/auth';

function isMSC(location) {
    if (location) {
        if (location.data && location.data.attributes && location.data.attributes.includes("MSC")) {            
            return true
        } 
    }

    return false
}

function isRewardsNonParticipating(location) {
    if (location) {
        if (location.data && location.data.attributes && location.data.attributes.includes("Rewards Non-Participating")) {            
            return true
        } 
    }

    return false
}


const Header = () => {
    const dispatch = useDispatch()
    const screen768andlarger = useMediaQuery('md');  
    const cart = useSelector(state => state.cart.cart)   

    const [hideAccount, setHideAccount] = useState(false)

    let openTermsAndConditions = useSelector(state => state.auth.termsAndConditionsPop)

    let locationStore = useSelector(state => state.location.location)

    const openLocationSidebar = () => {
        dispatch(locationActions.setLocationsSideBarState())   
        if (cart) {
            if (cart.deliverymode === 'pickup' || cart.deliverymode === 'curbside') {
                dispatch(locationActions.setVirtualDeliveryType('pickup')) 
            }
            if (cart.deliverymode === 'dispatch') {
                dispatch(locationActions.setVirtualDeliveryType('delivery'))
            }
        }  else {
            dispatch(locationActions.setVirtualDeliveryType('pickup'))
        }       
        
    }  

    const closeTermsAndConditions = () => {
        dispatch(authActions.openTermsAndConditions())
    }

    const acceptTermsAndCondtions = () => {
        dispatch(authActions.openTermsAndConditions())
        setCookie('terms_agreed', true, 3000) //3000 days
    }

    return <>
        <HeaderWrapper>
            <div className="container-full">
                <div className={classes.header_inner}>
                    <div className={classes.logo}>
                        {!locationStore && <Link to='/'>
                                {/* {screen768andlarger && <img src={logoCoBranded} alt='logo' className={classes.logo_cobranded} width={154} height={66}/>}
                                {!screen768andlarger && <img src={logoCoBrandedMobile} alt='logo' className={classes.logo_cobranded} width={45} height={45}/>} */}
                                <img src={logoMSC} alt='logo' className={classes.logo_msc}/>
                            </Link>}
                        {locationStore && locationStore.id && <Link to={locationHomeUrl(locationStore)}>
                            <img src={logoMSC} alt='logo' className={classes.logo_msc}/>
                            {/* {locationStore.cobranded && <>
                                {screen768andlarger && <img src={logoCoBranded} alt='logo' className={classes.logo_cobranded} width={154} height={66}/>}
                                {!screen768andlarger && <img src={logoCoBrandedMobile} alt='logo' className={classes.logo_cobranded} />}
                            </>}
                            {!locationStore.cobranded && locationStore.data.attributes.includes("MSC") && <img src={logoMSC} alt='logo' className={classes.logo_msc}/>}
                            {!locationStore.cobranded && !locationStore.data.attributes.includes("MSC") && <img src={logoGAC} alt='logo' className={classes.logo_gac} width={180} height={16}/>} */}
                        </Link>}
                    </div>

                    <div className={classes.header_controls}>
                        {screen768andlarger && <div className={classes.header_controls_item}>
                            {!locationStore && <button className={`btn ${classes.btn_location}`} onClick={openLocationSidebar} title='Find you store'>Start Your Order</button>}
                            {/* btn-radiate  */}
                            {locationStore && locationStore.id && <HeaderLocationDesktop />}
                        </div>}
                        <div className={`${classes.header_controls_item} ${classes.header_controls_item_sign_in}`}>
                            <HeaderSignIn />
                        </div>
                        {/* {!hideAccount && <div className={`${classes.header_controls_item} ${classes.header_controls_item_sign_in}`}>
                            <HeaderSignIn />
                        </div>} */}
                        <div className={classes.header_controls_item}>
                            <HeaderCartButton />
                        </div>

                    </div>
                </div>
            </div>
            
            {!screen768andlarger && <HeaderLocationMobile />}

            <SidebarLocationContainer />
            <SidebarAuthContainer />
            <SidebarContainer />
            {openTermsAndConditions && <Popup closeInfoHandler={closeTermsAndConditions} transition={openTermsAndConditions}>
                <div className={classes.terms_container}>
                    <h3>Updated Terms and Conditions</h3>  
                    <div>
                        <p>Great American Cookies & Marble Slab Creamery Rewards program terms and conditions have changed.</p>
                        <p>By continuing, you agree to the <a href="https://www.greatamericancookies.com/rewards-terms/" target="_blank" rel="noreferrer">Terms & Conditions</a>.</p>
                    </div>
                    <div className={classes.terms_container_footer}>
                        <button className="btn" onClick={acceptTermsAndCondtions}>Accept</button>
                    </div>
                </div>
            </Popup>}
        </HeaderWrapper>
        
    </>
    
}

export default Header;