import { Suspense } from "react"
import AllLocations from "../components/common/AllLocations"
import BannerSimpleSet from "../components/common/BannerSimpleSet"
import { getALLLocations } from "../scripts/fetchApi"
import LocationsCounter from "../components/common/LocationsCounter"
import { defer, Await, useLoaderData } from "react-router-dom"
import LoadingWrapper from "../components/common/LoadingWrapper"


import classes from './Error.module.css'
// import PageWrapper from "../components/common/PageWrapper"
import { getItemFromStorage, setStorageItem } from "../scripts/localStorage"

const Directory = () => {

    const data = useLoaderData()  

    return <Suspense fallback={<LoadingWrapper text=""/>}>
        <Await resolve={data.data} errorElement={<div className="container-full">
                <section className={classes.error}>
                    <h1 className="text-center">Page Not Found</h1>
                    <h3 className="text-center">No cookies for you here :( !</h3>
                </section>
            </div>}>
            {() => <>
                <div className="overflow_container">
                    <div className="overflow_container">
                        <BannerSimpleSet title={'Ice Cream Near Me'} subtitle={<LocationsCounter />} supTitle={'our locations'} type={'CNC'} styleType={'directory'}/>

                        <AllLocations />
                    </div>
                </div>
            </>}
        </Await>
    </Suspense>
}

export default Directory


async function loadData(slug, prodID) {
    const storage = getItemFromStorage('allLocations') 

    const fetchLocation = async () => {

        const loc = await getALLLocations()

        if (!loc && loc.loc) {
            throw new Response("Not Found", { status: 404 });
        } else {           
            setStorageItem('allLocations', {
                all: loc
            }, 3600)   
            return loc
        }
        
    }

    
    if (storage === null) {    // there is nothing in storage = search location 
        const loc = fetchLocation()
        return loc
    } else {  // there is a location 
        
        return {
            loc: getItemFromStorage('allLocations').all
        }
    }
}

export async function loader({ request, params}) {
    // const slug = params.locationSlug
    // const prodID = params.productSlug || null
    
    return defer({
        data: loadData()
    })    
}