
const genericFetch = async (url, method, body) => {
    const options = {
        method: method || 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Basic ${btoa( process.env.REACT_APP_API_USERNAME + ':' + process.env.REACT_APP_API_PASSWORD )}`,
            "ProjectID": process.env.REACT_APP_PROJECT_ID,
            "ProjectName": "MSC"
        },
        
    }

    if (body) {
        options.body = JSON.stringify(body)
    }
    const response = await fetch(url, options)

    if (!response.ok) {
        const resData = await response.json();
        return {
            message: resData,
            success: false
        }
    } else {
        const resData = await response.json();

        return resData
    }
}

// locations apis 
export const getALLLocations = async () => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/get-restaurants?no_cache=1`,
        null,
        null
    )

    return data
}

export const getLocationsByLatLng = async (coordinates, type) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/restaurants/nearby?delivery=${type}&lat=${coordinates.lat}&lng=${coordinates.lng}`,
        null,
        null
    )

    return data
}

export const getLocationsByLatLngWithDate = async (coordinates, type, date, time) => { //2023-10-29 23:45:00
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/restaurants/nearby?advance=${date} ${time}&delivery=${type}&lat=${coordinates.lat}&lng=${coordinates.lng}`,
        null,
        null
    )

    return data
}

export const getLocationsById = async (id) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/restaurants/${id}`,
        null,
        null
    )

    return data

}
// END locations apis 



// menu apis
export const getMenuFromLocationId = async (id) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/restaurants/${id}?with_menu=1`,
        null,
        null
    )

    return data

}

export const getMenuNoId = async (id) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/restaurants/menu`,
        null,
        null
    )

    return data

}

// END menu apis


// product apis
export const getProductFromID = async (restaurantID, prodId) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/restaurants/${restaurantID}/product/${prodId}`,
        null,
        null
    )

    return data
}
// END product apis


// cart apis
export const createCart = async (body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart`,
        'POST',
        body
    )

    return data
}

export const getCartById = async (cartId, token) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}?authtoken=${token}`,
        null,
        null
    )

    return data
}

export const addProductToCartAndCreateCart = async (body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/item`,
        'POST',
        body
    )

    return data
}

export const addProductToExistingCart = async (cartId, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/item`,
        'POST',
        body
    )

    return data
}

export const addProductToExistingCartUpdate = async (cartId, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/item`,
        'PUT',
        body
    )

    return data
}

export const updateProductInCart = async (cartId, productId, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/item/${productId}`,
        'PUT',
        body
    )

    return data
}

export const deleteProductInCart = async (cartId, productId) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/item/${productId}`,
        'DELETE',
        null
    )

    return data
}

export const cartSetDeliveryMethod = async (cartId, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/set-delivery-method`,
        'PUT',
        body
    )

    return data
}

export const cartSetDeliveryAddress = async (cartId, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/set-delivery-address`,
        'PUT',
        body
    )

    return data
}


export const getCartUpsells = async (cartId, locationId) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/eligible-upsell-items/${locationId}`,
        null,
        null
    )

    return data
}

export const addUpsellsToCart = async (cartId, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/eligible-upsell-items`,
        'POST',
        body
    )

    return data
}


export const validateCart = async (cartId) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/validate`,
        null,
        null
    )

    return data 
}

export const getBillingSchemes = async (cartId) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/billing-schemes`,
        null,
        null
    )

    return data
}

export const getDeliveryTimeslots = async (cartId, locId, date) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/get-time-slots/${cartId}/${locId}/${date}`,
        null,
        null
    )

    return data
}

export const setVehicle = async (cartId, restaurantId, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/set-vehicle/${restaurantId}`,
        'POST',
        body
    )

    return data
}

export const setTip = async (cartId, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/set-tip-amount`,
        'POST',
        body
    )

    return data
}

export const cartCheckout = async (cartId, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/checkout`,
        'POST',
        body
    )

    return data
}

export const addCartCoupon = async (cartId, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/coupon`,
        'POST',
        body
    )

    return data
}

export const removeCartCoupon = async (cartId) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/coupon`,
        'DELETE',
        null
    )

    return data
}

export const OrderTrackerInfo = async (orderId) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}`,
        null,
        null
    )

    return data
}

export const orderDispatchStatus = async (orderId) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/orders/${orderId}/dispatch-status`,
        null,
        null
    )

    return data
}

export const getAvailableCartRewards = async (token, cartId) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}/cart/${cartId}/rewards`,
        null,
        null
    )

    return data
}

export const applyCartRewards = async (cartId, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/rewards`,
        'POST',
        body
    )

    return data
}

export const deleteCartRewards = async (cartId, rewardId) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/rewards/${rewardId}`,
        'DELETE',
        null
    )

    return data
}


export const reorderFromFavoriteOrPrevious = async (token, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}/create-favorite-order`,
        'POST',
        body
    )

    return data
}

export const reorderFromPreviousOrder = async (token, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}/reorder`,
        'POST',
        body
    )

    return data
}

export const cartCCSFtoken = async (cartId, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartId}/ccsftoken`,
        'POST',
        body
    )

    return data
}

// END cart apis

// user apis
export const userLogin = async (body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/login`,
        'POST',
        body
    )

    return data
}

export const userLogout = async (token) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}/logout`,
        'POST',
        null
    )

    return data
}

export const registerUser = async (body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/register`,
        'POST',
        body
    )

    return data
}

export const userForgotPassword = async (body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/reset-password`,
        'POST',
        body
    )

    return data
}

export const getuserInfo = async (token, auth_provider) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}${auth_provider ? '?auth_provider='+auth_provider : ''}`, 
        null,
        null
    )

    return data
}

export const updateUserInfo = async (token, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}`, 
        'PUT',
        body
    )

    return data
}

export const getUserFavoriteOrders = async (token) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}/favorite-orders`, 
        null,
        null
    )

    return data
}
export const getUserRewards = async (token, auth_provider) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}/account-rewards-information${auth_provider ? '?auth_provider='+auth_provider : ''}`, 
        // `${process.env.REACT_APP_API_URL}/api/v1/user/${token}/account-olo-rewards-information`, 
        null,
        null
    )

    return data
}
export const getUserRecentOrders = async (token) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}/recent-orders`, 
        null,
        null
    )

    return data
}




export const setFavoriteLocation = async (token, restaurantId) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}/favorite-locations/${restaurantId}`,
        'POST',
        null
    )

    return data
}
export const getUserFavoriteLocation = async (token) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}/favorite-locations`, 
        null,
        null
    )

    return data
}

export const deleteUserFavoriteLocation = async (token, locId) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}/favorite-locations/${locId}`, 
        'DELETE',
        null
    )

    return data
}

export const setFavoriteOrder = async (token, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}/favorite-orders`,
        'POST',
        body
    )

    return data
}

export const removeFavoriteOrder = async (token, favoriteID) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${token}/favorite-orders/${favoriteID}`,
        'DELETE',
        null
    )

    return data
}

//End user apis


export const getAccessTokenOloLogin = async (body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/connect/token`,
        'POST',
        body
    )

    return data
}

export const oneTimeSigninLink = async (body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/onetimesigninlink`,
        'POST',
        body
    )

    return data
}


export const otpFetchToken = async (project_id, identifier) => {
    
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/otp/token/${project_id}/${identifier}`, 
        null,
        null
    )

    return data
}


export const setCartReferral = async (cartid, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/${cartid}/referrals`,
        'POST',
        body
    )

    return data
}


export const RequestMigration = async (authtoken, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${authtoken}/migrate-account`,
        'POST',
        body
    )

    return data
}

export const submitMigration = async (authtoken, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${authtoken}/migrate-account`,
        'PUT',
        body
    )

    return data
}

export const GetUserViaPunchMobileAPI = async (authtoken, body) => {
    const data = await genericFetch(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${authtoken}/migrate-user`,
        'POST',
        body
    )

    return data
} 