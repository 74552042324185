import { useDispatch, useSelector } from "react-redux"

import classes from './EmptyCart.module.css'
import { useNavigate } from "react-router-dom"
import { locationActions } from "../../store/location"
import { cartActions } from "../../store/cart"

// import birdUrl from '../../assets/bird.svg'
import meltedIce from '../../assets/meltedicecream.svg'
import { useEffect, useState } from "react"
import { getItemFromStorage } from "../../scripts/localStorage"

const EmptyCart = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [mscLocation, setMscLocation] = useState(false)
    let locationStore = useSelector(state => state.location.location)
    const storage = getItemFromStorage('currentLocation') 

    const openLocationSidebar = () => {
        dispatch(cartActions.setCartSidebarStep("order"))
        dispatch(cartActions.setCartSidebarState())    
        dispatch(locationActions.setLocationsSideBarState())        
    }

    const navigateTohandler = () => {
        dispatch(cartActions.setCartSidebarState()) 
        // navigate(`${locationStore.slug}/menu/cookie-cakes`, { state: { toHolidays: 'Holidays' } })
        navigate(`/menu/${locationStore.slug}/ice-cream-cakes`, { state: { toHolidays: 'Holidays' } })
    }


    

    
    useEffect(() => {
        if (storage && storage.currentLocation && storage.currentLocation.data && storage.currentLocation.data.attributes && storage.currentLocation.data.attributes.includes("MSC")) {
            setMscLocation(true)
        } else {
            setMscLocation(false)
        }
    }, [storage])
    


    return <div className={classes.no_items_container}>
        <div className={classes.no_items_container_inner}>
            {/* {!mscLocation && <img src={birdUrl} alt="bird eating crumbs"/>} */}
            <img src={meltedIce} alt="melted icecream"/>
            <h3 className={classes.title}>Your bag is empty!</h3>
            <p className={classes.description}>It’s lonely in here.</p>
            <div className={classes.no_items_container_footer}>
                {locationStore && <button to="cookie-cakes" className='btn-fancy' onClick={navigateTohandler}>Order Now</button>}
                {/* {!noLocation && <Link to="cookie-cakes" className='btn-fancy'>Order Now</Link>} */}
                {!locationStore && <button to="cookie-cakes" className='btn-fancy' onClick={openLocationSidebar}>Order Now</button>}
            </div>
        </div>
    </div>
}

export default EmptyCart