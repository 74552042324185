import { Link, useParams } from "react-router-dom"

import { useEffect, useState } from "react"

import classes from './AllLocations.module.css'

import { getItemFromStorage } from "../../scripts/localStorage"
import ArrowSliderRightIcon from "../../icons/arrowsliderright"
import { stateLabelValues } from "../../scripts/helpers"
import CityLocation from "./CityLocation"
import Location from "../locations/Location"

const CityLocations = () => {

    const [cityStores, setCityStores] = useState(null)
    const [currentState, setCurrentState] = useState(null)
    const [currentCity, setCurrentCity] = useState(null)
    
    // const [bannerString, setBannerString] = useState(null)
    const params = useParams()

    const storage = getItemFromStorage('allLocations')   

    useEffect(() => {
        if (storage && storage.all) {
            if (params && params.stateName && params.cityName) {

                stateLabelValues?.forEach(item => {
                    if (item.value.toLowerCase() === params.stateName) {
                        setCurrentState(item.label)
                    }
                })
                setCurrentCity(params.cityName.split("_").join(' '))

                let stateStore = storage.all.filter(item => item.address.state.toLowerCase() === params.stateName)


                if (stateStore) {
                    let cityStore = stateStore.filter(item => item.address.city.toLowerCase() === params.cityName.split('_').join(" "))
                   
                    if (cityStore) {
                        setCityStores(cityStore)
                        
                    } else {
    
                    }
                }          

                
            }
        }
    }, [])

    return <div className={classes.container_wrapper}>
        <div className={classes.container}>
            <div className={classes.breadcrumb}>
                <Link to={'/directory'} className={classes.breadcrumb_link}>All Stores</Link>
                <span className={classes.separator}><ArrowSliderRightIcon /></span>
                <Link to={`/directory/${params.stateName}`} className={classes.breadcrumb_link}>{currentState}</Link>
                <span className={classes.separator}><ArrowSliderRightIcon /></span>
                <div className={classes.breadcrumb_current}>{currentCity}</div>
            </div>
            <h2 className={classes.title}>{currentCity} Locations</h2>

            <>
            {!cityStores && <>No stores found</>}
            {cityStores && <div className="grid-3 gap_24">
                {cityStores.map(item => (
                    <CityLocation key={item.id} data={item}/>
                    // <Location key={item.id} data={item}/>
                ))}    
            </div>}
        </>
        </div>
    </div>
}

export default CityLocations